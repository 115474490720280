import React, { Component } from 'react';
import Plan from './Plan';
import Ajax from '../../common/ajax';
import Config from '../../config';
import Waiting from '../../common/containers/Waiting';
import { withNamespaces } from 'react-i18next';
import Row from '../../common/containers/Row';
import Col from '../../common/containers/Col';
import Testimonials from './Testimonials';
import TwitterTestimonials from './TwitterTestimonials';
import GetSiteControl from '../../integrations/GetSiteControl';
import AvisoGhostwriting from '../../common/AvisoGhostwriting';
import SinglePlan from './SinglePlan';
import SignupDiscount from '../SignupDiscount';

class Plans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plans: [],
      processing: false,
      currencyCode: 'BRL',
      currencyLabel: 'R$',
    };

    this.fetchPlans = this.fetchPlans.bind(this);
    this.configureCheckout = this.configureCheckout.bind(this);
  }

  componentDidMount() {
    this.configureCheckout();
  }

  fetchPlans() {
    this.setState((state) => ({ ...state, processing: true }));
    const url = `${Config.apiHost}plans/all/`;
    Ajax.get(url)
      .done((data) => {
        this.setState((state) => ({ ...state, plans: data }));
      })
      .always(() => {
        this.setState((state) => ({ ...state, processing: false }));
      });
  }

  configureCheckout() {
    const url = `${Config.apiHost}checkouts/config/`;
    Ajax.get(url).then((config) => {
      this.setState((state) => ({
        ...state,
        currencyCode: config.currency_code,
        currencyLabel: config.currency_label,
      }));
      this.fetchPlans();
    });
  }

  render() {
    const { i18n } = this.props;
    const { plans, processing } = this.state;

    return (
      <Waiting isProcessing={processing}>
        <Row>
          <Col md={10} mdOffset={1}>
            <h3 className="text-center">
              {i18n.t('Utilizado por mais de 1,5 milhão de alunos e professores em mais de 5 mil instituições')}
            </h3>
            <h3 className="text-center">{i18n.t('Mais de 2 milhões de documentos criados')}</h3>
          </Col>
        </Row>

        {!processing && (
          <Row>
            <Col md={10} mdOffset={1}>
              <Testimonials />
            </Col>
          </Row>
        )}

        <Row>
          <Col md={10} mdOffset={1}>
            <div className="hidden-lg hidden-xl">
              <SignupDiscount />
            </div>
          </Col>
        </Row>

        {plans.length === 1 && !processing && (
          <div className="row">
            <br />
            <div className="col col-md-offset-4 col-md-4">
              <SinglePlan
                plan={plans[0]}
                isLast={true}
                currencyCode={this.state.currencyCode}
                currencyLabel={this.state.currencyLabel}
              />
            </div>
          </div>
        )}

        {plans.length > 1 && (
          <div className={plans.length === 3 ? 'row' : ''}>
            <div className={plans.length === 3 ? 'col-md-10 col-md-offset-1' : ''}>
              <div className="row pricing-tables">
                {plans.map((plan, idx) => {
                  return (
                    <div key={plan.id} className={plans.length === 3 ? 'col-md-4' : 'col-md-3'}>
                      {!processing && (
                        <Plan
                          plan={plan}
                          isLast={idx === plans.length - 1}
                          currencyCode={this.state.currencyCode}
                          currencyLabel={this.state.currencyLabel}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        <h3 className="text-center text-muted">
          {i18n.t('É muito mais barato do que perder tempo com normas de formatação complicadas!')}
        </h3>

        <h4 className="text-center">
          {i18n.t('Precisa de mais de uma licença?')}{' '}
          <a role="button" onClick={() => GetSiteControl.show('437700')}>
            {i18n.t('Entre em contato')}
          </a>
        </h4>

        <hr />

        {!processing && (
          <>
            {/* <Row>
              <Col md={10} mdOffset={1}>
                <Testimonials />
              </Col>
            </Row> */}

            <Row>
              <Col md={10} mdOffset={1}>
                {plans && plans.length > 0 && <TwitterTestimonials isAbroad={this.state.currencyCode != 'BRL'} />}
              </Col>
            </Row>

            <Row>
              <Col md={10} mdOffset={1}>
                <div className="row" style={{ marginTop: '3%' }}>
                  <div className="col-md-12">
                    <p>
                      *{' '}
                      {i18n.t(
                        'Você pode criar quantos documentos desejar de acordo com o espaço de armazenamento disponível no plano que você adquirir.',
                      )}
                      <br />* {i18n.t('O formato de exportação dos documentos é PDF.')}
                      <br />*{' '}
                      {i18n.t(
                        'Não exportamos o documento para Microsoft Word. Entretanto, existem softwares capazes de fazer isso como as versões mais recentes do',
                      )}{' '}
                      <i>Acrobat PDF Reader</i> {i18n.t('e')} <i>MS Word</i>.
                    </p>

                    <AvisoGhostwriting />
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Waiting>
    );
  }
}

export default withNamespaces()(Plans);
